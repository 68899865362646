const state = () => ({
    data: [
        {
            name: 'ANZ',
            points: 50
        },
        {
            name: 'Afra',
            points: 50
        },
        {
            name: 'Ajuma',
            points: 30
        },
        {
            name: 'Al-Qasar',
            points: 60
        },
        {
            name: 'Altin Gün',
            points: 60
        },
        {
            name: 'Amaria BB',
            points: 45
        },
        {
            name: 'Amelie Lens',
            points: 50
        },
        {
            name: 'Animistic Beliefs',
            points: 50
        },
        {
            name: 'Arctic Monkeys',
            points: 60
        },
        {
            name: 'Arlo Parks',
            points: 35
        },
        {
            name: 'Aurora',
            points: 50
        },
        {
            name: 'BADBADNOTGOOD',
            points: 50
        },
        {
            name: "Bab L' Bluz",
            points: 40
        },
        {
            name: 'Balthazar',
            points: 60
        },
        {
            name: 'Bella',
            points: 30
        },
        {
            name: 'Blck Mamba',
            points: 30
        },
        {
            name: 'Bonnefooi',
            points: 30
        },
        {
            name: 'Bring Me The Horizon',
            points: 60
        },
        {
            name: 'Burna Boy',
            points: 45
        },
        {
            name: 'Buunshim',
            points: 50
        },
        {
            name: 'Caribou',
            points: 50
        },
        {
            name: 'Cavetown',
            points: 25
        },
        {
            name: 'Chamos',
            points: 30
        },
        {
            name: 'Channel Tres',
            points: 55
        },
        {
            name: 'Charlotte Adigéry & Bolis Pupul',
            points: 50
        },
        {
            name: 'Charmaine',
            points: 30
        },
        {
            name: 'Chloe Moriondo',
            points: 35
        },
        {
            name: 'Chocolate Swirl',
            points: 30
        },
        {
            name: 'Cimcity',
            points: 30
        },
        {
            name: 'Cleopatrick',
            points: 60
        },
        {
            name: 'Coco Maria',
            points: 30
        },
        {
            name: 'Dadi Freyr',
            points: 50
        },
        {
            name: 'Daphni',
            points: 50
        },
        {
            name: 'David Keenan',
            points: 5
        },
        {
            name: 'De Schuurman',
            points: 50
        },
        {
            name: "De'Wayne",
            points: 60
        },
        {
            name: 'Declan McKenna',
            points: 60
        },
        {
            name: 'Dubioza Kolektiv',
            points: 55
        },
        {
            name: 'Eefje de Visser',
            points: 50
        },
        {
            name: 'Eileen',
            points: 30
        },
        {
            name: 'Ela Nihus',
            points: 50
        },
        {
            name: 'Enny',
            points: 55
        },
        {
            name: 'Eris Drew & Octa Octa',
            points: 50
        },
        {
            name: 'FKJ',
            points: 50
        },
        {
            name: 'Fatima Vamaha',
            points: 50
        },
        {
            name: 'Fever 333',
            points: 60
        },
        {
            name: 'Fjaak Live',
            points: 50
        },
        {
            name: 'Floating Points',
            points: 50
        },
        {
            name: 'For those I love',
            points: 50
        },
        {
            name: 'Former',
            points: 50
        },
        {
            name: 'Four Tet',
            points: 50
        },
        {
            name: 'Fred Again..',
            points: 50
        },
        {
            name: 'Froukje',
            points: 35
        },
        {
            name: 'GO_A',
            points: 50
        },
        {
            name: 'Gayle',
            points: 5
        },
        {
            name: 'Geese',
            points: 60
        },
        {
            name: 'Glass Animals',
            points: 50
        },
        {
            name: 'Goldband',
            points: 50
        },
        {
            name: 'Gotu Jim',
            points: 55
        },
        {
            name: 'Halogenix',
            points: 50
        },
        {
            name: 'Hang Youth',
            points: 60
        },
        {
            name: 'Headie One',
            points: 55
        },
        {
            name: 'Hellie',
            points: 30
        },
        {
            name: 'Henry X',
            points: 50
        },
        {
            name: 'Hope Tala',
            points: 25
        },
        {
            name: 'Imanu',
            points: 50
        },
        {
            name: 'JXDN',
            points: 60
        },
        {
            name: 'Jack Harlow',
            points: 55
        },
        {
            name: 'Jael',
            points: 30
        },
        {
            name: 'James Blake',
            points: 50
        },
        {
            name: 'Jarreau Vandal',
            points: 30
        },
        {
            name: 'Jason Lemonth',
            points: 30
        },
        {
            name: 'Jerreau',
            points: 30
        },
        {
            name: 'Joe & The Shitboys',
            points: 60
        },
        {
            name: 'Jungle',
            points: 50
        },
        {
            name: 'Jyoty',
            points: 30
        },
        {
            name: 'KAMAL.',
            points: 50
        },
        {
            name: 'KC Funkaholic & Boye',
            points: 30
        },
        {
            name: 'Kamma & Masalo',
            points: 50
        },
        {
            name: 'Kamo Mphela',
            points: 50
        },
        {
            name: 'Kelly Lee Owens',
            points: 50
        },
        {
            name: 'Kevin & The Animals',
            points: 55
        },
        {
            name: 'Ki/Ki',
            points: 50
        },
        {
            name: 'Kills Birds',
            points: 60
        },
        {
            name: 'Kobosil',
            points: 50
        },
        {
            name: 'Koffee',
            points: 45
        },
        {
            name: 'Koreless',
            points: 50
        },
        {
            name: 'La Brigade du Kif',
            points: 45
        },
        {
            name: 'Lauren Sanderson',
            points: 55
        },
        {
            name: 'Lewis Capaldi',
            points: 60
        },
        {
            name: 'Liam Gallagher',
            points: 60
        },
        {
            name: 'Lion Kojo',
            points: 30
        },
        {
            name: 'Loshh',
            points: 45
        },
        {
            name: 'Mahalia',
            points: 55
        },
        {
            name: 'Major League DJZ',
            points: 50
        },
        {
            name: 'Mdou Moctar',
            points: 60
        },
        {
            name: 'Michael Kiwanuka',
            points: 25
        },
        {
            name: 'Minyo Crusaders',
            points: 45
        },
        {
            name: 'Mirella Kroes',
            points: 50
        },
        {
            name: 'Nana Adjoa',
            points: 35
        },
        {
            name: 'Noah Cyrus',
            points: 25
        },
        {
            name: 'Noisia Sluit Af',
            points: 50
        },
        {
            name: 'Noord Nederlands Orkest',
            points: 2
        },
        {
            name: 'Nova Twins',
            points: 60
        },
        {
            name: 'Nu Genea',
            points: 50
        },
        {
            name: 'Oscar and the Wolf',
            points: 50
        },
        {
            name: 'Overmono',
            points: 50
        },
        {
            name: 'Palaye Roral',
            points: 60
        },
        {
            name: 'Parquet Courts',
            points: 60
        },
        {
            name: 'Parrish Smith',
            points: 50
        },
        {
            name: 'Pendulum',
            points: 50
        },
        {
            name: 'Permanent Destruction',
            points: 50
        },
        {
            name: 'Philou Louzolo',
            points: 50
        },
        {
            name: 'PinkPantheress',
            points: 50
        },
        {
            name: 'Posij',
            points: 50
        },
        {
            name: 'Prins S. en de Geit',
            points: 50
        },
        {
            name: 'Pushin Wood Soundsystem',
            points: 30
        },
        {
            name: 'Q',
            points: 45
        },
        {
            name: 'Remi Wolf',
            points: 35
        },
        {
            name: 'Rob Manga',
            points: 30
        },
        {
            name: 'Rüfüs du Sol',
            points: 50
        },
        {
            name: 'Sad Night Dynamite',
            points: 50
        },
        {
            name: 'Sam Fender',
            points: 60
        },
        {
            name: 'Sevdaliza',
            points: 50
        },
        {
            name: 'Shame',
            points: 60
        },
        {
            name: 'Sidi Wacho',
            points: 55
        },
        {
            name: "Sinead O'Brien",
            points: 60
        },
        {
            name: 'Slowthai',
            points: 55
        },
        {
            name: 'Soichi Terada',
            points: 50
        },
        {
            name: 'Stromae',
            points: 50
        },
        {
            name: 'Suze Ijò',
            points: 50
        },
        {
            name: 'Tash Sultana',
            points: 60
        },
        {
            name: 'The Hatters',
            points: 45
        },
        {
            name: 'The Kooks',
            points: 35
        },
        {
            name: 'The Lathums',
            points: 60
        },
        {
            name: 'The Murder Capital',
            points: 60
        },
        {
            name: 'The Opposites',
            points: 55
        },
        {
            name: 'The Upbeats',
            points: 50
        },
        {
            name: 'Tienson',
            points: 30
        },
        {
            name: 'Tjade & Moody Mehran',
            points: 50
        },
        {
            name: 'Tkay Maidza',
            points: 55
        },
        {
            name: 'Togo All Stars',
            points: 45
        },
        {
            name: 'Tonno Disko',
            points: 30
        },
        {
            name: 'Vigro Deep',
            points: 50
        },
        {
            name: 'Wes Lee',
            points: 50
        },
        {
            name: 'Wesley Joseph',
            points: 55
        },
        {
            name: 'Wet Leg',
            points: 60
        },
        {
            name: 'Weval',
            points: 50
        },
        {
            name: 'Yard Act',
            points: 36
        },
        {
            name: 'Yussef Dayes',
            points: 40
        },
        {
            name: 'Yves Tumor',
            points: 60
        },
        {
            name: 'Zwangere Guy',
            points: 55
        },
        {
            name: 'Zwarte Koffie',
            points: 30
        }
    ]
});

export default {
    namespaced: true,
    state
}