/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');

import Vue from 'vue';
import { gsap } from 'gsap';
import Vuex from 'vuex';
import { mapGetters } from 'vuex';
import imagesLoaded from 'imagesloaded';
import store from './store';
import * as PIXI from 'pixi.js';
require('pixi-particles');
import { Howl, Howler } from 'howler';
import { debounce } from 'lodash';

import CloudCanvas from './classes/cloud-canvas';

import Screen from './components/Screen';
import Logo from './components/images/Logo';
import Preloader from './components/Preloader';


Vue.use(Vuex);

const App = new Vue({
    el: '#app',
    delimiters: ['${', '}'],

    store,

    components: {
        Logo,
        Screen,
        Preloader
    },

    data() {
        return {
            showOverlay: true,
            video: null,
            lefstScreenVideo: null,
            show: false,
            preloading: true,
            showContent: false,
            soundScreen: null,
            soundButton: null,
            soundProcess: null,
            soundCrowd: null,
            soundPrinting: null,
            soundBgHum: null,
            soundSelect: null,
            showMachineFallback: false
        };
    },

    computed: {
        ...mapGetters({ selectedCocktail: 'user/getCocktail' }),
    },

    created() {
        // Don't show that PIXI console message.
        PIXI.utils.skipHello();
    },

    mounted() {
        EventBus.$on('preload-complete', () => {
            this.onPreloadComplete();
        });
        EventBus.$on('show-ticket', () => {
            this.showTicket();
        });

        EventBus.$on('window-resize', () => {
            if (!this.showOverlay) {
                Vue.nextTick(() => {
                    this.alignScreen();
                });
            }
        });

        EventBus.$on('start-experience', () => {
            this.createSounds();
            imagesLoaded(this.$el, () => {
                gsap.to('.scene', { duration: 1.6, y: '0%', scale: 1, delay: 1, ease: 'sine.out' });
                gsap.to(['.scene__plants-left', '.scene__plants-right'], { duration: 1.6, x: '0%', delay: 1, ease: 'sine.out' });
                gsap.to('#overlay-canvas', { duration: 1.2, autoAlpha: 1, delay: 1.5, ease: 'power4.out' });

                gsap.to('.scene__screen-on', { duration: 1, scaleX: 1, scaleY: 0.01, delay: 1.6, ease: 'power4.in' });
                gsap.to('.scene__screen-on', { duration: 1, scaleY: 1, delay: 2.6, ease: 'power4.out' });
                gsap.to('.scene__screen-on', { duration: 0.8, autoAlpha: 0, delay: 2.6, ease: 'circ.inOut' });
                gsap.to('.scene__screen-background', {
                    duration: 0.8,
                    autoAlpha: 1,
                    delay: 2.6,
                    ease: 'circ.inOut',
                    onStart: () => {
                        EventBus.$emit('show-intro-screen');
                        this.$el.addEventListener("mousemove", this.parallax);
                    }
                });

                gsap.delayedCall(2.9, () => {
                    this.soundScreen.play();
                })

                this.video = document.querySelector('.scene__machine-processor');
                this.lefstScreenVideo = document.querySelector('.scene__machine-processor-screen-left');
                this.lefstScreenVideo.playbackRate = 0.4;
                gsap.delayedCall(6, () => {
                    this.lefstScreenVideo.play()
                    this.soundBlips.play()
                });
                this.lefstScreenVideo.onended = () => {
                    gsap.delayedCall(6, () => {
                        this.lefstScreenVideo.play()
                        this.soundBlips.play()
                    });
                };

                EventBus.$on('start-mix', () => {
                    this.startMix();
                });

            });
        });

        Vue.nextTick(() => {
            this.alignScreen();

            this.initClouds();

            gsap.delayedCall(0.2, () => {
                document.querySelector('.scene').classList.add('scene--transforms');
            });
        });

        window.addEventListener('resize', this.onWindowResize);
    },

    methods: {
        createSounds() {
            this.soundBgHum = new Howl({
                src: ['media/sounds/bg-hum.mp3'],
                volume: 0.08,
                loop: true
            });
            this.soundBgHum.play();

            this.soundBgSong = new Howl({
                src: ['media/sounds/bg-sound-icarus.mp3'],
                volume: 0.02,
                loop: true
            });
            this.soundBgSong.play();

            this.soundScreen = new Howl({
                src: ['media/sounds/zap.mp3'],
                volume: 0.1,
                rate: 0.8
            });

            this.soundButton = new Howl({
                src: ['media/sounds/button-click.mp3'],
                volume: 1.2,
            });
            EventBus.$on('playButtonSound', () => {
                this.soundButton.play();
            });

            this.soundSelect = new Howl({
                src: ['media/sounds/button-selected.mp3'],
                volume: 0.4,
            });
            EventBus.$on('play-select-sound', () => {
                this.soundSelect.play();
            });

            this.soundProcess = new Howl({
                src: ['media/sounds/processor.mp3'],
                volume: 0.8,
            });

            this.soundCrowd = new Howl({
                src: ['media/sounds/crowd-cheering.mp3'],
                volume: 0.1,
            });

            this.soundPrinting = new Howl({
                src: ['media/sounds/printer.mp3'],
                volume: 1.3
            });

            this.soundBlips = new Howl({
                src: ['media/sounds/blips.mp3'],
                volume: 0.03,
                rate: 0.8
            });
        },
        onPreloadComplete() {
            this.showContent = true;
            gsap.to('.preloader', {
                autoAlpha: 0,
                delay: 0.4,
                duration: 0.8,
                onComplete: () => {
                    this.preloading = false;
                }
            });
        },
        startExperience() {
            this.showOverlay = false;
            EventBus.$emit('start-experience');
        },

        showTicket() {
            gsap.set('.overlay-blur-holder', { height: '100%', y: 0, duration: 0 });
            gsap.to('.overlay-blur', { opacity: 1, ease: 'sine.out', duration: 1, delay: 0.2 });
            gsap.to('.overlay-blur-inner', { y: '0%', ease: 'sine.out', duration: 1 });
            gsap.to('.overlay__content-reveal', { duration: 1.4, y: '0%', autoAlpha: 1, stagger: 0.2, ease: 'power4.out', delay: 1 });
        },

        startMix() {
            const bgHeight = document.querySelector('.scene__machine-processor-holder').clientHeight;
            const sceneHeight = document.querySelector('.scene').clientHeight;
            const tHeight = bgHeight - sceneHeight;
            gsap.to('.scene', { y: -tHeight, scale: 1, duration: 2, ease: 'sine.out' });
            this.lefstScreenVideo.onended = () => {
                this.lefstScreenVideo.pause();
                this.soundBlips.stop();
            }
            this.soundProcess.play();

            this.video.onended = () => {
                EventBus.$emit('show-ticket');
                this.soundCrowd.play();
                gsap.delayedCall(1, () => {
                    this.soundBgHum.stop();
                    this.soundProcess.stop();
                })
            };

            gsap.delayedCall(0.5, () => {
                const promise = this.video.play();

                if (promise !== undefined) {
                    promise.then(_ => {

                    }).catch(error => {
                        this.showMachineFallback = true;

                        gsap.delayedCall(1.5, () => {
                            EventBus.$emit('show-ticket');
                            this.soundCrowd.play();

                            gsap.delayedCall(1, () => {
                                this.soundBgHum.stop();
                                this.soundProcess.stop();
                            })
                        });
                    });
                }

                this.soundBgSong.fade(0.02, 0.1, 6000);

                gsap.delayedCall(3, () => {
                    this.soundProcess.fade(1, 0, 1000);
                    this.soundPrinting.play();
                })
            });
        },

        showForm() {
            localStorage.removeItem('usbl.3584a4e219a7.c.e4ec636bc6a7');
            localStorage.removeItem('usbl.3584a4e219a7.c.c7e60046da40');
            localStorage.removeItem('usbl.3584a4e219a7.c.fff9f068af3f');
            localStorage.removeItem('usbl.3584a4e219a7.c.2d4be23bfded');
            localStorage.removeItem('usbl.3584a4e219a7.c.8d57603b4a2d');
            localStorage.removeItem('usbl.3584a4e219a7.c.8bfd6c3c18e5');
            localStorage.removeItem('usbl.3584a4e219a7.c.58b1b4066094');
            localStorage.removeItem('usbl.3584a4e219a7.c.ab60f9f1b7e5');
            localStorage.removeItem('usbl.3584a4e219a7.c.da937ee0dfa5');
            localStorage.removeItem('usbl.3584a4e219a7.c.f1eb0f10da81');
            localStorage.removeItem('usbl.3584a4e219a7.c.577a92cf9833');
            localStorage.removeItem('usbl.3584a4e219a7.c.235d2b2e8111');
            localStorage.removeItem('usbl.3584a4e219a7.c.243123a8630a');
            localStorage.removeItem('usbl.3584a4e219a7.c.b074f7258020');
            localStorage.removeItem('usbl.3584a4e219a7.c.6438e336c79f');
            localStorage.removeItem('usbl.3584a4e219a7.c.a95777624379');
            localStorage.removeItem('usbl.3584a4e219a7.c.2e2da50dece4');
            localStorage.removeItem('usbl.3584a4e219a7.c.e73ff4ebbd1b');
            localStorage.removeItem('usbl.3584a4e219a7.c.c163a5d71a5d');
            localStorage.removeItem('usbl.3584a4e219a7.c.7a7bea7ecb8c');
            localStorage.removeItem('usbl.3584a4e219a7.c.7a63708d1a1b');
            localStorage.removeItem('usbl.3584a4e219a7.c.5eaf6a1e1ea6');
            localStorage.removeItem('usbl.3584a4e219a7.c.2b8dffb8da90');
            localStorage.removeItem('usbl.3584a4e219a7.c.cada0e24990a');
            localStorage.removeItem('usbl.3584a4e219a7.c.5fa08bff831c');
            localStorage.removeItem('usbl.3584a4e219a7.c.7b02fd08955a');
            localStorage.removeItem('usbl.3584a4e219a7.c.357d658dba8b');
            localStorage.removeItem('usbl.3584a4e219a7.c.c47f77fc1dfc');
            localStorage.removeItem('usbl.3584a4e219a7.c.59440ae0edd1');
            localStorage.removeItem('usbl.3584a4e219a7.c.0d1d07b40599');
            localStorage.removeItem('usbl.3584a4e219a7.c.0667d3ef941a');
            localStorage.removeItem('usbl.3584a4e219a7.c.29952ab3606e');
            localStorage.removeItem('usbl.3584a4e219a7.c.28d71d09ef15');
            localStorage.removeItem('usbl.3584a4e219a7.c.f04aa297e5b0');
            localStorage.removeItem('usbl.3584a4e219a7.c.d5c31df887b4');
            localStorage.removeItem('usbl.3584a4e219a7.v');
            localStorage.removeItem('usbl.3584a4e219a7.c.9fdea843883f');
            localStorage.removeItem('usbl.3584a4e219a7.c.f9c407eb677b');
            localStorage.removeItem('usbl.3584a4e219a7.c.bf623e24c648');
            localStorage.removeItem('usbl.13d36db2d9c4.c.2e1719ac0f7a');
            localStorage.removeItem('usbl.13d36db2d9c4.c.1cce9d7eb986');
            localStorage.removeItem('usbl.13d36db2d9c4.c.a0df64b2302e');
            localStorage.removeItem('usbl.13d36db2d9c4.c.5083a741c920');
            localStorage.removeItem('usbl.13d36db2d9c4.c.695d219e30c3');
            localStorage.removeItem('usbl.13d36db2d9c4.c.d88ecd815cb2');
            localStorage.removeItem('usbl.13d36db2d9c4.c.a35233fe8f4d');
            localStorage.removeItem('usbl.13d36db2d9c4.c.e193d78bd5ba');
            localStorage.removeItem('usbl.13d36db2d9c4.c.866b6ec9a71f');
            localStorage.removeItem('usbl.13d36db2d9c4.c.53eab18512a3');
            localStorage.removeItem('usbl.13d36db2d9c4.c.f8b388ea4088');
            localStorage.removeItem('usbl.13d36db2d9c4.c.63d426498811');
            localStorage.removeItem('usbl.13d36db2d9c4.c.f1a7de370df6');
            localStorage.removeItem('usbl.13d36db2d9c4.c.b42f5bca0856');
            localStorage.removeItem('usbl.13d36db2d9c4.c.4123db2c4e94');
            localStorage.removeItem('usbl.13d36db2d9c4.c.35e5530a7692');
            localStorage.removeItem('usbl.13d36db2d9c4.c.3bb4d700077a');
            localStorage.removeItem('usbl.13d36db2d9c4.c.423a8ea57b14');
            localStorage.removeItem('usbl.13d36db2d9c4.c.f38843df462e');
            localStorage.removeItem('usbl.13d36db2d9c4.c.d4c2387ad160');
            localStorage.removeItem('usbl.13d36db2d9c4.c.8799b8f6844e');

            window.usabilla_live('virtualPageView');
            window.usabilla_live('trigger', 'Lowlands22');
        },

        parallax(event) {
            this.$el.querySelectorAll(".scene__plant").forEach((shift) => {
                const position = shift.getAttribute("value");
                gsap.to(shift, { x: ((window.innerWidth - event.pageX - (window.innerWidth / 2)) * position) / 490 });
                gsap.to(shift, { y: ((window.innerHeight - event.pageY - (window.innerWidth / 2)) * position) / 490 });
            });

            const bg = this.$el.querySelector('.scene__background')
            const bgValue = bg.getAttribute("value");
            gsap.to(bg, { x: ((window.innerWidth - event.pageX - (window.innerWidth / 2)) * bgValue) / 490 });
            gsap.to(bg, { y: ((window.innerHeight - event.pageY - (window.innerWidth / 2)) * bgValue) / 490 });
        },

        initClouds() {
            let canvas = document.getElementById('overlay-canvas');
            if (canvas) {
                this.canvas = new CloudCanvas(canvas);
            }
        },

        alignScreen() {
            if (window.matchMedia('(min-width: 996px)').matches) {
                document.querySelector('.scene').style.top = '80px';
            } else if (window.matchMedia('(max-width: 995px)').matches) {
                document.querySelector('.scene').style.top = '50px';
            }

            const screen = document.querySelector('.screen');
            const screenHeight = screen.getBoundingClientRect().height;
            const screenTopPosition = screen.getBoundingClientRect().top;
            const center = window.innerHeight / 2;
            const centeredScreenTop = center - (screenHeight / 2);
            const screenOffset = centeredScreenTop - screenTopPosition;

            if (window.matchMedia('(min-width: 996px)').matches) {
                document.querySelector('.scene').style.top = (110 + screenOffset) + 'px';
            } else if (window.matchMedia('(max-width: 995px)').matches) {
                document.querySelector('.scene').style.top = (60 + screenOffset) + 'px';
            }
        },

        /**
         * Raised when the client window is resizing.
         *
         * @return {Void}
         */
        onWindowResize: debounce(() => {
            EventBus.$emit('window-resize');
        }, 500),
    }
});