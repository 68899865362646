import * as PIXI from 'pixi.js';
require('pixi-particles');

export default class CloudCanvas {
    constructor(el) {
        if (!el) {
            return;
        }

        this.canvas = el;
        let rendererOptions = {
            view: this.canvas,
            transparent: true
        };

        this.stage = new PIXI.Container();
        this.emitter = null;
        this.emitterBottom = null;
        this.renderer = PIXI.autoDetectRenderer(this.canvas.width, this.canvas.height, rendererOptions);

        // Create Spawnrects
        this.mobileState = this.isMobile();
        this.setupSpawnRects();

        // Create a new emitter
        this.emitterTop = this.newEmitter(this.stage, this.spawnRectTop);
        this.emitterBottom = this.newEmitter(this.stage, this.spawnRectBottom);
        this.emitterLeft = this.newEmitter(this.stage, this.spawnRectLeft);
        this.emitterRight = this.newEmitter(this.stage, this.spawnRectRight);

        // Create a new big emitter
        // 

        // Calculate the current time
        var elapsed = Date.now();

        // Update function every frame
        var update = () => {
            // Update the next frame
            requestAnimationFrame(update);

            var now = Date.now();

            // The emitter requires the elapsed
            // number of seconds since the last update
            this.emitter.update((now - elapsed) * 0.001);
            this.emitterBottom.update((now - elapsed) * 0.001);
            this.emitterLeft.update((now - elapsed) * 0.001);
            this.emitterRight.update((now - elapsed) * 0.001);
            elapsed = now;

            // Should re-render the PIXI Stage
            this.renderer.render(this.stage);
        };

        EventBus.$on('window-resize', this.resize.bind(this));
        EventBus.$emit('window-resize');

        // Start emitting
        this.emitterRight.emit = true;

        // Start emitting
        this.emitterLeft.emit = true;

        // Start emitting
        this.emitterBottom.emit = true;

        // Start emitting
        this.emitter.emit = true;

        // Start the update
        update();
    }

    resize() {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        this.renderer.resize(this.canvas.width, this.canvas.height);

        // Update spawn rect
        this.setupSpawnRects();

        this.mobileState = this.isMobile();

        // Reset
        this.resetEmitters();
    }

    newEmitter(stage, spawnRect) {
        const scale = (this.mobileState) ? 1 : 1.5;

        return new PIXI.particles.Emitter(

            // The PIXI.Container to put the emitter in
            // if using blend modes, it's important to put this
            // on top of a bitmap, and not use the root stage Container
            stage,

            // The collection of particle images to use
            [
                PIXI.Texture.fromImage('media/images/cloud_01.png'),
                PIXI.Texture.fromImage('media/images/cloud_02.png'),
                PIXI.Texture.fromImage('media/images/cloud_02.png')
            ],

            // Emitter configuration, edit this to change the look
            // of the emitter
            {
                "alpha": {
                    list: [
                        {
                            value: 0,
                            time: 0
                        },
                        {
                            value: 0.3,
                            time: 0.5
                        },
                        {
                            value: 0.2,
                            time: 0.8
                        },
                        {
                            value: 0,
                            time: 1
                        }
                    ],
                    isStepped: false
                },
                "scale": {
                    "start": scale,
                    "end": scale,
                    "minimumScaleMultiplier": 0.5
                },
                "color": {
                    "start": "#ffffff",
                    "end": "#ffffff"
                },
                "speed": {
                    list: [
                        {
                            value: 20,
                            time: 0
                        },
                        {
                            value: 10,
                            time: 1
                        }
                    ],
                    "minimumSpeedMultiplier": 0.5
                },
                "acceleration": {
                    "x": 0,
                    "y": 0
                },
                "maxSpeed": 0,
                "startRotation": {
                    "min": 0,
                    "max": -180
                },
                "noRotation": false,
                "rotationSpeed": {
                    "min": 0,
                    "max": 0
                },
                "lifetime": {
                    "min": 10,
                    "max": 20
                },
                "blendMode": "normal",
                "frequency": 2,
                "emitterLifetime": -1,
                "maxParticles": 10,
                "pos": {
                    "x": 0,
                    "y": 0
                },
                "addAtBack": false,
                "spawnType": "rect",
                "spawnRect": spawnRect
            }
        );
    }

    resetEmitters() {
        if (this.emitter) {
            this.emitter.emit = false;
            this.emitter.cleanup();
        }

        this.emitter = this.newEmitter(this.stage, this.spawnRectTop);
        this.emitter.emit = true;

        if (this.emitterBottom) {
            this.emitterBottom.emit = false;
            this.emitterBottom.cleanup();
        }

        this.emitterBottom = this.newEmitter(this.stage, this.spawnRectBottom);
        this.emitterBottom.emit = true;

        if (this.emitterLeft) {
            this.emitterLeft.emit = false;
            this.emitterLeft.cleanup();
        }

        this.emitterLeft = this.newEmitter(this.stage, this.spawnRectLeft);
        this.emitterLeft.emit = true;

        if (this.emitterRight) {
            this.emitterRight.emit = false;
            this.emitterRight.cleanup();
        }

        this.emitterRight = this.newEmitter(this.stage, this.spawnRectRight);
        this.emitterRight.emit = true;
    }

    setupSpawnRects() {
        // Update spawn rect
        if (window.matchMedia('(min-width: 1200px)').matches) {
            this.spawnRectTop = {
                "x": 0,
                "y": -window.innerHeight / 10,
                "w": window.innerWidth,
                "h": window.innerHeight / 10
            };

            this.spawnRectBottom = {
                "x": 0,
                "y": window.innerHeight + (window.innerHeight / 10),
                "w": window.innerWidth,
                "h": window.innerHeight / 10
            };

            this.spawnRectLeft = {
                "x": 0,
                "y": 0,
                "w": window.innerWidth / 20,
                "h": window.innerHeight
            };

            this.spawnRectRight = {
                "x": window.innerWidth - (window.innerWidth / 20),
                "y": 0,
                "w": window.innerWidth / 20,
                "h": window.innerHeight
            };
        } else if (window.matchMedia('(min-width: 1024px)').matches) {
            this.spawnRectTop = {
                "x": 0,
                "y": -window.innerHeight / 10,
                "w": window.innerWidth,
                "h": window.innerHeight / 10
            };

            this.spawnRectBottom = {
                "x": 0,
                "y": window.innerHeight + (window.innerHeight / 10),
                "w": window.innerWidth,
                "h": window.innerHeight / 10
            };

            this.spawnRectLeft = {
                "x": 0,
                "y": 0,
                "w": window.innerWidth / 20,
                "h": window.innerHeight
            };

            this.spawnRectRight = {
                "x": window.innerWidth - (window.innerWidth / 20),
                "y": 0,
                "w": window.innerWidth / 20,
                "h": window.innerHeight
            };
        } else if (window.matchMedia('(min-width: 768px)').matches) {
            this.spawnRectTop = {
                "x": 0,
                "y": -window.innerHeight / 10,
                "w": window.innerWidth,
                "h": window.innerHeight / 10
            };

            this.spawnRectBottom = {
                "x": 0,
                "y": window.innerHeight + (window.innerHeight / 10),
                "w": window.innerWidth,
                "h": window.innerHeight / 10
            };

            this.spawnRectLeft = {
                "x": -window.innerHeight / 10,
                "y": 0,
                "w": window.innerWidth / 20,
                "h": window.innerHeight
            };

            this.spawnRectRight = {
                "x": window.innerWidth + (window.innerWidth / 10),
                "y": 0,
                "w": window.innerWidth / 20,
                "h": window.innerHeight
            };
        } else if (window.matchMedia('(max-width: 767px)').matches) {
            this.spawnRectTop = {
                "x": 0,
                "y": -window.innerWidth / 10 * 4,
                "w": window.innerWidth,
                "h": window.innerHeight / 10
            };

            this.spawnRectBottom = {
                "x": 0,
                "y": window.innerHeight + ((window.innerHeight / 10) * 4),
                "w": window.innerWidth,
                "h": window.innerHeight / 10
            };

            this.spawnRectLeft = {
                "x": -window.innerWidth / 10 * 4,
                "y": 0,
                "w": window.innerWidth / 20,
                "h": window.innerHeight
            };

            this.spawnRectRight = {
                "x": window.innerWidth + ((window.innerWidth / 10) * 4),
                "y": 0,
                "w": window.innerWidth / 20,
                "h": window.innerHeight
            };
        }
    }

    isMobile() {
        return (window.innerWidth < 768);
    }

    destroy() {
        EventBus.$off('optimized-window-resize');
    }
}