const state = () => ({
    artists: [],
    cocktail: null
});

const mutations = {
    setArtists(state, artists) {
        state.artists = artists;
    },

    setCocktail(state, cocktail) {
        state.cocktail = cocktail;
    },
};

const getters = {
    getArtists(state) {
        return state.artists;
    },
    getCocktail(state) {
        return state.cocktail;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations
}