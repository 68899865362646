<template>
    <div class="screen">
        <Logo class="screen__logo screen-reveal" />

        <div class="screen__section screen__section--start">
            <div class="screen__start-content">
                <h1 class="screen__title textshadow">
                    Maak kans op
                </h1>
                <img class="screen__start-logo" src="media/images/lowlands-logo.png" alt="Lowlands">
                <h2 class="screen__title textshadow">
                    Tickets
                </h2>
                <h3 class="screen__small-title textshadow">
                    voor jou en<br>een vriend(in)
                </h3>
            </div>
            <div class="screen__start-btn btn-3d btn-3d--inverse textshadow-inverse" @click="startSelection(); playButtonSound();">
                <div class="btn-3d__upper">Start</div>
            </div>
        </div>

        <transition name="screen">
            <div v-show="activeScreen === 'loading'" class="screen__section">
                <div class="screen__loading-animation"></div>

                <h1 class="screen__loading-title screen__title textshadow">
                    Muziek heeft<br>invloed op je<br>smaakbeleving
                </h1>

                <div class="screen__loading-bar">
                    <div class="screen__loading-bar-text textshadow">
                        LOADING DATA {{ loadingPercentage }}%
                    </div>
                    <div class="screen__loading-bar-progress"></div>
                </div>
            </div>
        </transition>

        <transition name="screen">
            <div v-show="activeScreen === 'question-1'" class="screen__section screen__section--start-selection">
                <h1 class="screen__selection-overview-title screen__title screen__title--question textshadow">
                    Hoe laat<br>sta jij op tijdens<br>Lowlands?
                </h1>

                <div class="screen__answers">
                    <div @click.prevent="selectAnswer(1, 0)" class="screen__answers-answer textshadow" :class="{ 'screen__answers-answer--selected': answers.first === 0 }">A: Lekker vroeg, genieten van de rust met koffie</div>
                    <div @click.prevent="selectAnswer(1, 1)" class="screen__answers-answer textshadow" :class="{ 'screen__answers-answer--selected': answers.first === 1 }">B: De zon bepaalt dat voor mij</div>
                    <div @click.prevent="selectAnswer(1, 2)" class="screen__answers-answer textshadow" :class="{ 'screen__answers-answer--selected': answers.first === 2 }">C: Opstaan? 24/7 hacienda</div>
                </div>
                
                <div class="screen__tutorial-navigation">
                    <div class="screen__tutorial-navigation-btn screen__tutorial-navigation-btn--hidden screen__tutorial-navigation-btn--disabled">
                        Vorige
                    </div>
                    <div @click.prevent="nextQuestion(2)" class="screen__tutorial-navigation-btn" :class="{ 'screen__tutorial-navigation-btn--disabled': answers.first === null }">
                        Volgende
                    </div>
                </div>
            </div>
        </transition>

        <transition name="screen">
            <div v-show="activeScreen === 'question-2'" class="screen__section screen__section--start-selection">
                <h1 class="screen__selection-overview-title screen__title screen__title--question textshadow">
                    Waar sta jij<br>op de camping?
                </h1>

                <div class="screen__answers">
                    <div @click.prevent="selectAnswer(2, 0)" class="screen__answers-answer textshadow" :class="{ 'screen__answers-answer--selected': answers.second === 0 }">A: Gllamcamp met m'n eigen nespresso machine</div>
                    <div @click.prevent="selectAnswer(2, 1)" class="screen__answers-answer textshadow" :class="{ 'screen__answers-answer--selected': answers.second === 1 }">B: Ik heb geen camping nodig, all day bravo</div>
                    <div @click.prevent="selectAnswer(2, 2)" class="screen__answers-answer textshadow" :class="{ 'screen__answers-answer--selected': answers.second === 2 }">C: Het échte feestje is op de groepscamping!</div>
                </div>
                
                <div class="screen__tutorial-navigation">
                    <div @click.prevent="prevQuestion(1)" class="screen__tutorial-navigation-btn">
                        Vorige
                    </div>
                    <div @click.prevent="nextQuestion(3)" class="screen__tutorial-navigation-btn" :class="{ 'screen__tutorial-navigation-btn--disabled': answers.second === null }">
                        Volgende
                    </div>
                </div>
            </div>
        </transition>

        <transition name="screen">
            <div v-show="activeScreen === 'question-3'" class="screen__section screen__section--start-selection">
                <h1 class="screen__selection-overview-title screen__title screen__title--question textshadow">
                    Wat doe jij het<br>liefst op je laatste<br>ll-dag?
                </h1>

                <div class="screen__answers">
                    <div @click.prevent="selectAnswer(3, 0)" class="screen__answers-answer textshadow" :class="{ 'screen__answers-answer--selected': answers.third === 0 }">A: Ik sta rechtsvoor in de alpha</div>
                    <div @click.prevent="selectAnswer(3, 1)" class="screen__answers-answer textshadow" :class="{ 'screen__answers-answer--selected': answers.third === 1 }">B: Een koffiecocktail drinken</div>
                    <div @click.prevent="selectAnswer(3, 2)" class="screen__answers-answer textshadow" :class="{ 'screen__answers-answer--selected': answers.third === 2 }">C: Mensen turen bij la terraza</div>
                </div>
                
                <div class="screen__tutorial-navigation">
                    <div @click.prevent="prevQuestion(2)" class="screen__tutorial-navigation-btn">
                        Vorige
                    </div>
                    <div @click.prevent="finishQuestions" class="screen__tutorial-navigation-btn" :class="{ 'screen__tutorial-navigation-btn--disabled': answers.third === null }">
                        Volgende
                    </div>
                </div>
            </div>
        </transition>

        <transition name="screen">
            <div v-show="activeScreen === 'generate'" class="screen__section screen__section--generate">
                <div class="screen__generate-animation"></div>
                <div class="screen__generate-btn btn-3d btn-3d--inverse textshadow-inverse" @click="startMix(); playButtonSound();">
                    <div class="btn-3d__upper">Creëer jouw koffiecocktail</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Vue from 'vue';
import { gsap } from 'gsap';
import { mapGetters, mapMutations } from 'vuex';
import filter from 'lodash/filter';
import lottie from 'lottie-web';

import Logo from './images/LogoGlow';

export default {
    components: {
        Logo
    },

    data() {
        return {
            activeScreen: null,
            loadingPercentage: 0,
            selectionReady: false,
            selectedCocktail: null,
            formData: {},
            errors: null,
            lottie01: null,
            lottie02: null,
            answers: {
                first: null,
                second: null,
                third: null
            },
            pointsMap: [
                [11, 34, 4],
                [26, 39, 98],
                [75, 17, 67]
            ]
        };
    },

    computed: {
        ...mapGetters({ selectedArtists: 'user/getArtists' }),

        cocktails() {
            return this.$store.state.cocktails.data;
        }
    },

    mounted() {
        EventBus.$on('show-intro-screen', () => {
            this.showIntroScreen();
        });
    },

    methods: {
        ...mapMutations('user', [
            'setCocktail',
        ]),

        selectAnswer(question, selectedAnswer) {
            if(question === 1) {
                this.answers.first = selectedAnswer;
            }

            if(question === 2) {
                this.answers.second = selectedAnswer;
            }

            if(question === 3) {
                this.answers.third = selectedAnswer;
            }

            EventBus.$emit('play-select-sound');
        },

        prevQuestion(question) {
            if(question === 1) {
                this.activeScreen = 'question-1';
            }

            if(question === 2) {
                this.activeScreen = 'question-2';
            }

            this.playButtonSound();
        },

        nextQuestion(question) {
            if(question === 2) {
                if(this.answers.first === null) {
                    return;
                }

                this.activeScreen = 'question-2';
            }

            if(question === 3) {
                if(this.answers.second === null) {
                    return;
                }

                this.activeScreen = 'question-3';
            }

            this.playButtonSound();
        },

        finishQuestions() {
            if(this.answers.third === null) {
                return;
            }

            this.activeScreen = 'generate';
        },

        playButtonSound() {
            EventBus.$emit('playButtonSound');
        },

        showIntroScreen() {
            const introScreenTl = gsap.timeline({
                delay: 0.6,
                onComplete: () => {
                    this.activeScreen = 'start';
                }
            });

            introScreenTl.to('.screen__logo', { duration: 0.6, autoAlpha: 0.95, ease: 'power4.inOut' });
            introScreenTl.to('.screen__logo', { duration: 1, scale: 0.3, y: '-170%', ease: 'power4.out' }, 1.4);
            introScreenTl.to('.screen__section--start', {
                duration: 1,
                autoAlpha: 1,
                ease: 'power4.inOut',
                onStart: () => {
                    document.querySelector('.screen__start-btn').style.display = 'flex';
                }
            }, 1.4);
        },

        startSelection() {

            this.initLottieAnimations();

            gsap.to('.screen__section--start', {
                duration: 0.6,
                autoAlpha: 0,
                ease: 'power4.out',
            });

            gsap.delayedCall(0.3, () => {
                this.activeScreen = 'question-1';
            });

            // this.loadingAnimation.play();

            // const that = this;
            // const loadingScreenTl = gsap.timeline({
            //     delay: 0.6,
            //     onComplete: () => {
            //         this.activeScreen = 'question-1';
            //     }
            // });

            // loadingScreenTl.to('.screen__loading-bar-progress', {
            //     duration: 5,
            //     scaleX: 1,
            //     onUpdate: function() {
            //         that.loadingPercentage = Math.round(this.progress() * 100);
            //     },
            //     ease: 'power3.inOut'
            // });
        },

        initLottieAnimations() {
            this.loadingAnimation = lottie.loadAnimation({
                container: document.querySelector('.screen__loading-animation'),
                renderer: 'svg',
                loop: true,
                autoplay: false,
                rendererSettings: {
                    progressiveLoad: false,
                },
                path: 'media/animations/loading.json'
            });

            this.generateAnimation = lottie.loadAnimation({
                container: document.querySelector('.screen__generate-animation'),
                renderer: 'svg',
                loop: true,
                autoplay: false,
                rendererSettings: {
                    progressiveLoad: false,
                },
                path: 'media/animations/generate.json'
            });
        },

        completeArtistsSelection() {
            this.activeScreen = 'generate';
            EventBus.$emit('complete-artist-selection');
            this.generateAnimation.play();
        },

        startMix() {
            // Select cocktail for user
            let totalPoints = 0;

            totalPoints = this.pointsMap[0][this.answers.first] + this.pointsMap[1][this.answers.second] + this.pointsMap[2][this.answers.third];

            console.log(totalPoints);

            this.cocktails.forEach(element => {
                if(element.scores.includes(totalPoints)) {
                    this.selectedCocktail = element;
                }
            });

            this.setCocktail(this.selectedCocktail);

            EventBus.$emit('start-mix');
        },
    }
};
</script>

<style lang="scss" scoped>

</style>
