<template>
    <div class="preloader">
        <div class="preloader__inner">
            <img src="media/images/N_logo_white_square.png" class="preloader__logo" alt="logo">
            <span class="preloader__line">
                <span class="preloader__active-line"></span>
            </span>
            <span class="preloader__progress">
                Loading...
            </span>
        </div>
    </div>
</template>

<script>
import Preload from 'preload-it';
import { gsap } from 'gsap';
export default {
    components: {
    },
    data() {
        return {
            progress: 0,
        };
    },
    mounted() {
        this.$nextTick(this.preload);
    },
    beforeDestroy() {
    },
    methods: {
        preload() {
            const urls = [
                'media/images/screen-background.jpg',
                'media/images/machine.png',
                'media/images/plants-left.png',
                'media/images/plants-right.png',
                'media/sounds/bg-hum.mp3',
                'media/sounds/bg-sound-icarus.mp3'
            ];
            const preload = Preload();
            preload.fetch(urls);
            preload.oncomplete = (items) => {
                gsap.to(['.preloader__progress', '.preloader__logo'], { delay: 1, autoAlpha: 0, duration: 0.8 });
                gsap.delayedCall(0.5, () => {
                    this.onStartClick();
                })
            };
            preload.onprogress = (event) => {
                this.progress = event.progress;
                gsap.to('.preloader__active-line', { scaleX: event.progress / 100, duration: 0.2 });
            };
            preload.onfetched = (item) => {
                // console.log(item);
            };
            preload.onerror = (item) => {
                gsap.to('.preloader__active-line', { scaleX: 1, duration: 2 });
                gsap.to(['.preloader__progress', '.preloader__logo'], { autoAlpha: 0, duration: 0.8, delay: 2,
                    onComplete: () => {
                        this.onStartClick();
                    }
                });
            };
            gsap.delayedCall(4, () => {
                if(this.progress < 90) {
                    gsap.to('.preloader__active-line', { scaleX: 1, duration: 1 });
                    gsap.to(['.preloader__progress', '.preloader__logo'], { autoAlpha: 0, duration: 0.8, delay: 1});
                    gsap.delayedCall(1, () => {
                        this.onStartClick();
                    });
                }
            });
        },
        onStartClick() {
            gsap.to('.preloader__line', {
                scale: 0,
                delay: 1,
                duration: 0.8,
                onComplete: () => {
                    EventBus.$emit('preload-complete');
                }
            });
        },
    }
};
</script>

<style>
</style>