const state = () => ({
    data: [
        {
            key: 'cucumber-lemon-basil-cold-brew',
            name: 'Cucumber lemon Basil Cold Brew',
            scores: [140, 169],
        },
        {
            key: 'glam-llow',
            name: 'Glam & Llow',
            scores: [104, 77],
        },
        {
            key: 'mango-vanilla-iced-frappe',
            name: 'Mango Vanilla Iced Frappe',
            scores: [135, 199],
        },
        {
            key: 'mexicaanse-fiesta',
            name: 'Mexicaanse Fiesta',
            scores: [112, 184],
        },
        {
            key: 'mint-matcha-nitro',
            name: 'Mint & Matcha Nitro',
            scores: [117, 176],
        },
        {
            key: 'nespresso-martini',
            name: 'Nespresso Martini',
            scores: [47, 60, 118],
        },
        {
            key: 'nespresso-tonic',
            name: 'Nespresso Tonic',
            scores: [126, 207],
        },
        {
            key: 'pink-beach-mocktail',
            name: 'Pink Beach Mocktail',
            scores: [54, 119, 127],
        },
        {
            key: 'recharge-royal',
            name: 'Recharge Royal',
            scores: [67, 105, 177],
        },
        {
            key: 'tropical-iced-rio',
            name: 'Tropical Iced Rio',
            scores: [90, 149],
        },
        {
            key: 'vertuwow',
            name: 'Vertuwow',
            scores: [97, 110, 125],
        },
    ]
});

export default {
    namespaced: true,
    state
}